import styled from "styled-components";

export const Page = styled.div.attrs({ className: "pageClassName" })`
  width: 794px;
  height: 1122px;
  display: flex;
  flex-direction: column;
  /* justify-content: space-around; */
  /* align-items: center; */
  background-color: #fff;
  border: 1px solid #ccc;
  
  box-sizing: border-box;
  overflow: hidden;
`;