import React, { useEffect, useState } from "react";
import SearchBar from "../../common/SearchBar";
import styled from "styled-components";
import { Box, Checkbox, Divider, IconButton } from "@mui/material";

import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import { group_to_titles } from "./reportRenderer/helperFunctions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { PrimeButton, SecButton } from "../../styles/styles";

import CloseIcon from "@mui/icons-material/Close";
import { prettierName } from "../../common/helperFunctions";

const Form = styled.form`
  display: flex;
  flex-direction: column;
  max-height: 600px;
  padding: 0px 16px;

  margin-bottom: 16px;
`;

const CableSpecHeader = styled("header")`
  background: #009ec6;
  display: flex;
  justify-content: space-between;
  align-items: center;

  color: white;

  border-radius: 8px 8px 0 0;
  padding: 8px;

  h4 {
    margin: 0;
  }
`;
const StyledIconButton = styled(IconButton)`
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background-color: ${(props) =>
    props.active ? "#e0e0e0" : "transparent"} !important;
  transition: background-color 0.2s ease, transform 0.2s ease;

  &:hover {
    background-color: #009ec641 !important;
    transform: scale(1.1);
  }
`;

const CableSpecs = styled("div")`
  border: 2px solid #009ec6;
  border-radius: 0px 0px 8px 8px;

  padding: 8px;
`;

const UserSelectCard = styled("div")`
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  width: 100%;
  margin: 8px 0;

  .userEmail {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 150px;

    &:hover {
      overflow: visible;
    }
  }
`;

const CloseModal = styled("div")`
  position: absolute;
  right: 16px;
  top: 16px;
`;

const ButtonGroup = styled.div`
  display: flex;
  gap: 10px;
`;

const ActionButton = styled("button")`
  border: none;
  border-radius: 8px;
  width: 100px;
  padding: 8px;
  margin-right: 8px;
  background: white;

  &:hover {
    background-color: rgba(255, 255, 255, 0.5) !important;
    transform: scale(1.1);
  }
`;

const PillButton = styled("button")`
  background-color: ${(props) =>
    props.selected ? "#009ec6" : "#d9d9d9"} !important;

  border-radius: 50px;
  border: none;

  font-size: 1rem;

  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")} !important;

  color: ${(props) => (props.selected ? "white" : "black")} !important;

  padding: 16px;
  width: 100%;
`;

const isNKT = true;

function DatasheetConfig({
  cablesSpecs,
  handleClose,
  includedSpecs,
  setIncludedSpecs,
  preSetConfig,
  setPreSetConfig,
  workspace,
  setWorkspace,
}) {
  const [formattedSpecs, setFormattedSpecs] = useState({});
  const [expanded, setExpanded] = useState({ group: [null] });
  const [highlight, setHighlight] = useState(false);
  const [filterOptions, setFilterOptions] = useState([]);

  const handleFilter = (e) => {
    if (e) {
      const item = document.getElementById(e.value);
      item.scrollIntoView();
      item.highlight = true;
    }
  };

  const handleRemoveSpec = (names) => {
    console.log(names);
    let temp = [...includedSpecs];

    names.forEach((name) => {
      temp = temp.filter((spec) => spec !== name);
    });

    // Only update workspace save if preSetConfig is custom
    if (preSetConfig === "custom")
      setWorkspace((workspace) => ({ ...workspace, output_settings: temp }));

    // Update the included specs to change the UI
    setIncludedSpecs(temp);
  };

  const handleAddSpec = (names) => {
    const temp = [...includedSpecs];
    for (let i = 0; i < names.length; i++) {
      temp.push(names[i]);
    }

    // Only update workspace save if preSetConfig is custom
    if (preSetConfig === "custom")
      setWorkspace((workspace) => ({ ...workspace, output_settings: temp }));

    // Update the included specs to change the UI
    setIncludedSpecs(temp);
  };

  const handleExpand = (group, e) => {
    if (expanded.group.indexOf(group) === -1) {
      setExpanded((expanded) => ({ group: [...expanded.group, group] }));
    } else {
      const temp = expanded.group;
      temp.splice(expanded.group.indexOf(group), 1);

      setExpanded({ group: temp });
    }
  };

  const formatCreateInput = (names, spec = null) => {
    const formatted = [];
    for (let i = 0; i < names.length; i++) {
      const formattedName = names[i].replaceAll("_", " ");
      formatted.push({
        value: names[i],
        label: formattedName.charAt(0).toUpperCase() + formattedName.slice(1),
        spec: spec,
      });
    }

    return formatted;
  };

  const isIncluded = (specs) => {
    return includedSpecs.indexOf(specs) !== -1;
  };

  const handlePreSetSelection = (pre_set) => {
    if (Object.keys(formattedSpecs).length > 0) {
      switch (pre_set) {
        case "custom":
          if (preSetConfig !== "custom") {
            setIncludedSpecs(workspace);
            setPreSetConfig(pre_set);
          } else {
            let temp = group_to_titles([cablesSpecs[0]]);
            let orderedBy = [
              ...Object.keys(temp["Financial"]),
              ...Object.keys(temp["input data"]),
            ];

            Object.keys(temp).map((group) => {
              if (
                group !== "Financial" &&
                group !== "input data" &&
                group !== "Internal" &&
                group !== "Helper"
              )
                orderedBy.push(...Object.keys(temp[group]));
            });

            setIncludedSpecs(orderedBy);
            setPreSetConfig(null);
          }
          break;
        case "pricing":
          if (preSetConfig !== "pricing") {
            setIncludedSpecs([
              ...Object.keys(formattedSpecs["Financial"]),
              ...[
                "cable_name",
                "cable_type",
                "conductor_material",
                "conductor_size",
                "maximum_voltage",
                "nominal_voltage",
                "insulation_material",
                "metallic_screen_material",
                "metallic_screen_system",
                "settings:financial:date",
              ],
            ]);
            setPreSetConfig(pre_set);
          } else {
            let temp = group_to_titles([cablesSpecs[0]]);
            let orderedBy = [
              ...Object.keys(temp["Financial"]),
              ...Object.keys(temp["input data"]),
            ];

            Object.keys(temp).map((group) => {
              if (
                group !== "Financial" &&
                group !== "input data" &&
                group !== "Internal" &&
                group !== "Helper"
              )
                orderedBy.push(...Object.keys(temp[group]));
            });

            setIncludedSpecs(orderedBy);
            setPreSetConfig(null);
          }
          break;
        case "datasheet":
          if (preSetConfig !== "datasheet") {
            let temp = { ...formattedSpecs };

            let options = [];
            Object.keys(temp).map((keys) => {
              options.push(...Object.keys(temp[keys]));
            });

            // Koni is Dumb - Seb was right (KW 2024)

            // 53 on frontend
            // 56 in array
            // 57 in excel
            const universalDatasheet = [
              ...Object.keys(formattedSpecs["Financial"]),
              "cable_name",
              "cable_type",
              "conductor_material",
              "conductor_size",
              "nominal_voltage",
              "insulation_material",
              "metallic_screen_material",
              "metallic_screen_system",
              "settings:financial:date",
              "system_voltage", // Checked
              "maximum_voltage", // Checked
              "rated_impulse_withstand_voltage", // Checked
              "system_frequency", // Checked
              "rated_current_iec_60287_ac", // Checked
              "max_three_phase_fault_current", // Checked
              "max_single_phase_fault_current", // Checked
              "settings:cable:single_core_cable_bonding_type", // Checked
              "conductor_ac_resistance", // Checked
              "positive_sequence_impedance", // Checked
              "zero_sequence_impedance", // Checked
              "single_core_inductance", // Checked
              "metallic_screens_capacitance", // Checked
              "charging_current", // Checked
              "charging_reactive_power", // Checked
              "conductor_loss_iec_60287_ac", // Checked
              "dielectric_loss", // Checked
              "metallic_screen_loss_iec_60287", // Checked
              "armour_loss_iec_60287", // Checked
              "total_loss_iec_60287_ac", // Checked
              "conductor_temperature", // Checked
              "metallic_screen_temperature", // Checked
              "cable_environment_type", // Checked
              "ground_temperature", // Checked
              "burial_depth", // Checked
              "ground_thermal_resistivity", // Checked
              "external_cable_diameter", // Checked
              "core_cable_diameter", // Checked
              "mass", // Checked
              "tensile_strength", // Checked
              "axial_stiffness", // Checked
              "min_bend_radius", // Checked
              "min_bend_radius_installation", // Checked
              "min_bend_radius_single_core", // Checked
              "min_bend_radius_single_core_single_bend", // Checked
              "max_allowable_pulling_tension_for_armour", //Checked
              "j_tube_diameter", // Checked
              //   "inner_semiconductive_insulation_thickness",
              "conductor_shield:thickness", // Checked
              "metallic_screen:material", // Checked
              "metallic_screen:type", // Checked
              "metallic_screen:thickness", // Checked
              "protective_sheath:material", // Checked
              "protective_sheath:thickness", // Checked
              "insulation:thickness", // Checked
              "conductor_temperature", // Checked
            //   "insulation_material_thermal_resistivity", 
              //   "outer_semiconductive_insulation_thickness",
              "insulation_shield:thickness", // Checked
              //   "filling_material_thermal_resistivity", INTERNAL
              "armour:bedding_material", // Checked
            //   "armour_bedding_thickness",
              "diameter_over_armour_bedding", // Checked
              "outer_cover:material", // Checked
              "outer_cover:thickness", // Checked
              "conductor_to_screen_thermal_resistance_t1", // Checked
              "screen_to_armour_thermal_resistance_t2", // Checked
              "armour_to_environment_thermal_resistance_t3", // Checked
              "external_environment_thermal_resistance_t4", // Checked
            ];

            // options.splice(options.indexOf("cost"), 1);
            // options.splice(options.indexOf("nn_cost"), 1);

            setIncludedSpecs(universalDatasheet);
            setPreSetConfig(pre_set);
          } else {
            let temp = group_to_titles([cablesSpecs[0]]);
            let orderedBy = [
              ...Object.keys(temp["Financial"]),
              ...Object.keys(temp["input data"]),
            ];

            Object.keys(temp).map((group) => {
              if (
                group !== "Financial" &&
                group !== "input data" &&
                group !== "Internal" &&
                group !== "Helper"
              )
                orderedBy.push(...Object.keys(temp[group]));
            });

            setIncludedSpecs(orderedBy);
            setPreSetConfig(null);
          }
          break;

        default:
          let temp = group_to_titles([cablesSpecs[0]]);
          let orderedBy = [
            ...Object.keys(temp["Financial"]),
            ...Object.keys(temp["input data"]),
          ];

          Object.keys(temp).map((group) => {
            if (
              group !== "Financial" &&
              group !== "input data" &&
              group !== "Internal" &&
              group !== "Helper"
            )
              orderedBy.push(...Object.keys(temp[group]));
          });

          setIncludedSpecs(orderedBy);
          setPreSetConfig(null);
          break;
      }
    }
  };

  useEffect(() => {
    if (cablesSpecs) {
      const temp = cablesSpecs;

      for (let i = 0; i < temp.length; i++) {
        Object.keys(temp[i]).map((key) => {
          if (typeof temp[i][key].val === "object") {
            delete temp[i][key];
          }
        });
      }

      const groups = group_to_titles(temp);
      groups["Input Cable Specification"] = groups["input data"];

      setFormattedSpecs(groups);
    }
  }, [cablesSpecs]);

  useEffect(() => {
    const temp = { ...formattedSpecs };
    if (Object.keys(temp).length > 0) {
      delete temp["Helper"];
      delete temp["input data"];
      delete temp["Internal"];

      Object.keys(temp).map((key) => {
        setFilterOptions((filterOptions) => [
          ...filterOptions,
          ...formatCreateInput(Object.keys(temp[key])),
        ]);
      });
    }
  }, [formattedSpecs]);

  return (
    <div
      className="cableModal"
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(0,0,0,0.6)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          backgroundColor: "background.paper",
          borderRadius: "8px",
          padding: 2,
          position: "fixed",
          inset: "auto 15%",
          boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
          border: "1px solid rgba(0, 0, 0, 0.1)",
          overflowY: "hidden",
        }}
      >
        <header>
          <h2>Results Filters</h2>
          <Divider style={{ marginBottom: "16px" }} sx={{ bgcolor: "black" }} />
          <CloseModal onClick={handleClose}>
            <StyledIconButton>
              <CloseIcon />
            </StyledIconButton>
          </CloseModal>
        </header>

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-end",
            padding: "0 16px",
          }}
        >
          <SearchBar
            width={"50%"}
            placeholder={"Search to Cable Specification . . ."}
            infoTitle={"Cable Collection"}
            isMulti={false}
            items={filterOptions}
            handleFilter={handleFilter}
          />

          <div
            style={{
              width: "40%",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-evenly",
            }}
          >
            <label>Pre-Sets :</label>
            <ButtonGroup>
              <PillButton
                selected={preSetConfig === "custom"}
                onClick={() => handlePreSetSelection("custom")}
              >
                Custom
              </PillButton>

              <Divider
                style={{ backgroundColor: "black", width: "2px" }}
                orientation="vertical"
                flexItem
              />

              <PillButton
                selected={preSetConfig === "pricing"}
                onClick={() => handlePreSetSelection("pricing")}
              >
                Price
              </PillButton>
              <PillButton
                selected={preSetConfig === "datasheet"}
                onClick={() => handlePreSetSelection("datasheet")}
              >
                Datasheet
              </PillButton>
            </ButtonGroup>
          </div>
        </div>

        <Form>
          <div
            style={{ overflowY: "scroll", height: "100%", marginTop: "16px" }}
          >
            {includedSpecs.length === 0 && "No cables analysed . . ."}
            {Object.keys(formattedSpecs).map((group) => {
              if (
                group !== "input data" &&
                group !== "Helper" &&
                group !== "Internal"
              ) {
                return (
                  <div style={{ marginTop: "8px", marginRight: "8px" }}>
                    <CableSpecHeader>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <StyledIconButton onClick={(e) => handleExpand(group)}>
                          <FontAwesomeIcon
                            color="white"
                            icon={
                              expanded.group.indexOf(group) !== -1
                                ? faChevronDown
                                : faChevronUp
                            }
                          />
                        </StyledIconButton>
                        <h4>{group}</h4>
                      </div>

                      <ButtonGroup>
                        <SecButton
                          type="button"
                          onClick={() =>
                            handleAddSpec(Object.keys(formattedSpecs[group]))
                          }
                        >
                          Add All
                        </SecButton>
                        <SecButton
                          type="button"
                          onClick={() =>
                            handleRemoveSpec(Object.keys(formattedSpecs[group]))
                          }
                        >
                          Remove All
                        </SecButton>
                      </ButtonGroup>
                    </CableSpecHeader>

                    <CableSpecs
                      style={
                        expanded.group.indexOf(group) !== -1
                          ? { display: "none" }
                          : {}
                      }
                    >
                      {Object.keys(formattedSpecs[group]).map((specs) => {
                        return (
                          <UserSelectCard highlight={highlight} id={specs}>
                            <Checkbox
                              value={specs}
                              title="Select"
                              aria-label="Select"
                              icon={
                                <RadioButtonUncheckedIcon fontSize="large" />
                              }
                              checkedIcon={
                                <RadioButtonCheckedIcon
                                  fontSize="large"
                                  color="#009ec6"
                                />
                              }
                              checked={isIncluded(specs)}
                              onChange={(e) => {
                                if (!e.target.checked) {
                                  handleRemoveSpec([e.target.value]);
                                } else {
                                  handleAddSpec([e.target.value]);
                                }
                              }}
                            />
                            <label>
                              {formattedSpecs[group][specs][0].screen_name
                                ? formattedSpecs[group][specs][0].screen_name
                                : prettierName(specs)}
                            </label>
                          </UserSelectCard>
                        );
                      })}
                    </CableSpecs>
                  </div>
                );
              }
            })}
          </div>
        </Form>

        <footer>
          <PrimeButton onClick={() => handleClose()}>Update</PrimeButton>
        </footer>
      </Box>
    </div>
  );
}

export default DatasheetConfig;
