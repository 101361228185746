import React, { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
// import Settings from "@mui/icons-material/Settings";

// import DialogActions from "@mui/material/DialogActions";
// import DialogContent from "@mui/material/DialogContent";
// import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import styled from "styled-components";
import ColorPicker from "./ColorPicker";
import { ApplyButton, MeButton, PrimeButton, SecButton } from "../../styles/styles";
import { Box, Checkbox, Divider, IconButton } from "@mui/material";

import { FileUpload } from 'primereact/fileupload';
import CloseIcon from '@mui/icons-material/Close';

import { InputText } from 'primereact/inputtext';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";

const Form = styled.form`
  display: flex;
  flex-direction: column;
  max-height: 600px;
  padding: 0px 20px 0px 20px;
`;

const CloseModal = styled("div")`
  position: absolute;
  right: 16px;
  top: 16px;
`;

const GroupHeader = styled("header")`
  background: #009ec6;
  display: flex;
  justify-content: space-between;
  align-items: center;

  color: white;

  padding: 8px;
  
  h4 {
    margin: 0;
  }
`
const StyledIconButton = styled(IconButton)`
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background-color: ${(props) =>
    props.active ? "#e0e0e0" : "transparent"} !important;
  transition: background-color 0.2s ease, transform 0.2s ease;

  &:hover {
    background-color: #009ec641 !important;
    transform: scale(1.1);
  }
`;

const Row = styled("div")`
  /* height: 50%; */
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;

  gap: 4px;
  padding: 4px;

  border-radius: 8px;

  background-color: white;
  color: black;
`;

const SpanTitle = styled("span")`
  width: 35%;
  height: 3rem;
  
  background-color: ${(props) =>
    props.missing ? "rgb(206, 69, 69)" : (props.required ? "#009ec6" : "#f1f1f1")} !important;
  color: ${(props) =>
    props.missing ? "white" : (props.required ? "white" : "black")} !important;

  padding: 8px;
  border-radius: 4px;

  display: flex;
  align-items: center;
  /* justify-content: center; */

  font-weight: 600;
`;

const PdfForm = (props) => {
  const [open, setOpen] = React.useState(false);
  const [imageName, setImageName] = useState("");
  const [expanded, setExpanded] = useState({ group: [null] });

  const handleImageChange = (event) => {
    console.log("HERE+++++++++++++++++++++++++++++++");
    console.log(event.target.name);
    const file = event.target.files[0];
    const filename = event.target.files[0].name;
    const extension = filename.split(".").pop();

    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      props.setLogo({ data: reader.result, extension });
    };

    setImageName(event.target.files[0].name);
  };

  const handleChange = (event) => {
    console.log(event.target.id);
    props.setSettings({
      ...props.settings,
      [event.target.id]: event.target.value,
    });
  };

  const handleChangeColor = (colorId, color) => {
    props.setSettings({
      ...props.settings,
      [colorId]: color,
    });
  };

  const handleExpand = (group, e) => {
    if (expanded.group.indexOf(group) === -1) {
      setExpanded(expanded => ({ group: [...expanded.group, group] }))
    } else {
      const temp = expanded.group
      temp.splice(expanded.group.indexOf(group), 1)

      setExpanded({ group: temp })
    }
  }

  return (
    <div
      className='pdfForm'
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(0,0,0,0.6)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          backgroundColor: "background.paper",
          borderRadius: "8px",
          padding: 2,
          position: "fixed",
          inset: "10% 20%",
          boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
          border: "1px solid rgba(0, 0, 0, 0.1)",
          overflowY: "hidden",
          gap: "16px",
        }}
      >
        <header>
          <h2>Report Theme</h2>
          <Divider style={{}} sx={{ bgcolor: "black" }} />
          <CloseModal onClick={props.handleClose}>
            <StyledIconButton>
              <CloseIcon />
            </StyledIconButton>
          </CloseModal>
        </header>

        <div style={{ overflowY: "scroll" }}>
          <div style={{ marginTop: "8px", marginRight: "8px", border: "2px solid #009ec6", borderRadius: "8px" }}>
            <GroupHeader>
              <div style={{ display: "flex", alignItems: "center" }}>
                <StyledIconButton onClick={(e) => handleExpand("Metadata")}>
                  <FontAwesomeIcon color="white" icon={(expanded.group.indexOf("Metadata") !== -1) ? faChevronDown : faChevronUp} />
                </StyledIconButton>
                <h4>Metadata</h4>
              </div>
            </GroupHeader>

            <Form style={(expanded.group.indexOf("Metadata") !== -1) ? { display: "none" } : { padding: "8px" }}>
              {Object.keys(props.settings).map((key) => {
                if (!key.includes("Color")) {
                  return (
                    <Row className='cableRow'>
                      <SpanTitle>{key}</SpanTitle>
                      <InputText id={key} value={props.settings[key]} onChange={handleChange} />
                    </Row>
                  )
                }
              }
              )}
            </Form>
          </div>

          <div style={{ marginTop: "8px", marginRight: "8px", border: "2px solid #009ec6", borderRadius: "8px" }}>
            <GroupHeader>
              <div style={{ display: "flex", alignItems: "center" }}>
                <StyledIconButton onClick={(e) => handleExpand("Styles")}>
                  <FontAwesomeIcon color="white" icon={(expanded.group.indexOf("Styles") !== -1) ? faChevronDown : faChevronUp} />
                </StyledIconButton>
                <h4>Styles</h4>
              </div>
            </GroupHeader>

            <Form style={(expanded.group.indexOf("Styles") !== -1) ? { display: "none" } : { padding: "8px" }}>
              <div>
                <Row className='cableRow'>
                  <SpanTitle>Custom Logo</SpanTitle>
                  <label for="file-upload" class="custom-file-upload">
                    Upload Your Logo
                  </label>
                  <input id="file-upload" type="file" onChange={handleImageChange} />
                </Row>
              </div>

              <div>
                <Row className='cableRow'>
                  <SpanTitle>Primary Color</SpanTitle>
                  <ColorPicker
                    color={props.settings.primaryColor}
                    onColorChange={handleChangeColor}
                    colorId="primaryColor"
                  />
                </Row>
                <Row className='cableRow'>
                  <SpanTitle>Secondary Color</SpanTitle>
                  <ColorPicker
                    color={props.settings.secondaryColor}
                    onColorChange={handleChangeColor}
                    colorId="secondaryColor"
                  />
                </Row>
                <Row className='cableRow'>
                  <SpanTitle>Tertiary Color</SpanTitle>
                  <ColorPicker
                    color={props.settings.tertiaryColor}
                    onColorChange={handleChangeColor}
                    colorId="tertiaryColor"
                  />
                </Row>
              </div>
            </Form>
          </div>
        </div>

        <footer>
          <PrimeButton onClick={() => props.handleClose()}>Update</PrimeButton>
        </footer>
      </Box >
    </div >
  );
};

export default PdfForm;
