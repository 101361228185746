import React, { useEffect } from "react";
import { Page } from "./ReportStyles";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import styled from "styled-components";
import { toNormalDecimal } from "../../../common/helperFunctions";
import { prettierName } from "../../../common/helperFunctions";

// Styled components using styled-components
const StyledTableCell = styled(TableCell)`
  background-color: ${(props) => {
    // Priority to header color
    if (props.header) return props.headerColor || "#e0e0e0";
    // Next, check for firstCol and secondCol
    if (props.firstCol) return props.firstColor || "none";
    if (props.secondCol) return "lightgray" || "none";
    return "none";
  }};
  color: ${(props) =>
    props.header || props.firstCol
      ? "#ffffff !important"
      : "#5a5a5a !important"};
  font-weight: ${(props) =>
    props.firstCol || props.header || props.secondCol
      ? "600 !important"
      : "normal !important"};
  font-size: 12px !important;
  border: 1px solid #5a5a5a;

  width: 45px;
  max-width: 45px;
  min-width: 45px;

  // Set max-width and text wrapping for the first column cells
  ${(props) =>
    props.firstCol &&
    `
    width: 80px; // or any other fixed width
    max-width: 80px; // Ensure it doesn't exceed the specified width
    min-width: 80px; // Ensure it doesn't go below the specified width
    max-height: 40px;
    white-space: wrap;
    // word-wrap: break-word;
    // overflow: hidden;
    // text-overflow: ellipsis;
  `}

  ${(props) =>
    props.secondCol &&
    `
    width: 30px; // Adjust the width as needed
    max-width: 30px;
    min-width: 30px;
  `}

  // Add this for header cells to wrap text and have a max width
  ${(props) =>
    props.header &&
    `
    white-space: normal;
    word-wrap: break-word;
  `}
`;

const StyledTableRow = styled(TableRow)`
  &:nth-of-type(odd) {
    background-color: ${(props) =>
      !props.header ? "#f7f7f7" : "none"}; // Light grey color for odd rows
  }
  &:not(:last-child) {
    border-bottom: 1px solid black; // Optionally remove the bottom border from the rows to avoid double borders
  }
`;

// Helper function to chunk the data
const chunk = (arr, size) =>
  Array.from({ length: Math.ceil(arr.length / size) }, (v, i) =>
    arr.slice(i * size, i * size + size)
  );

const HeaderBox = styled.div`
  position: relative;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  min-height: 120px;
  //max-height: 120px;
  width: 100%;
  background-color: ${(props) => props.bgColor};

  box-sizing: border-box;
`;
const LogoBox = styled.div`
  width: 130px;
  height: 130px;

  padding: 20px;
  padding-top: 10px;

  display: flex;
  align-items: center;
  justify-content: center;

  box-sizing: border-box;
`;
const Logo = styled.img`
  width: 100%;
  box-sizing: border-box;
  object-fit: scale-down;

  aspect-ratio: 1;
`;

const Header = styled.div`
  box-sizing: border-box;
  width: 415px;
  padding: 18px;
  padding-left: 40px;
  display: flex;
  flex-direction: column;
`;

const HeaderTitle = styled.div`
  font-size: 28px;
  padding-bottom: 8px;
  box-sizing: border-box;
  text-align: left;
  color: #5a5a5a;
  font-weight: 800;
`;
const HeaderName = styled.div`
  text-align: left;
  font-size: 16px;
  color: #5a5a5a;
`;
const TitleGap = styled.div`
  height: 3px;
  border-radius: 4px;
  background-color: ${(props) => props.bgColor};
  margin-left: 20px;
  margin-right: 20px;
  /* padding-right:20px; */
`;
const Front = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
`;

const TopRightDetails = styled.div`
  position: absolute;
  top: 0px;
  right: 0px;
  display: flex;
  flex-direction: column;

  width: 270px;
  padding: 20px;
  /* max-height: 80px;
  min-height: 80px; */
  gap: 10px;
`;
const Detail = styled.div`
  display: flex;
  /* width:200px */
`;
const DetailName = styled.div`
  font-size: 13px;
  font-weight: bold;
  color: #5a5a5a;
`;
const DetailValue = styled.div`
  font-size: 13px;
  color: #5a5a5a;
  padding-left: 10px;
`;
const TableTitle = styled.div`
  font-size: 20px;
  color: #5a5a5a;
  padding: 20px;
  padding-bottom: 0px;
`;
const TableBox = styled.div`
  display: flex;
  padding: 20px;
`;
const TableRender = ({
  data,
  title,
  logo,
  previewPDF,
  settings,
  sortOrder,
  cables,
}) => {
  // if (!data || Object.keys(data).length === 0) return null;

  // sort headers reverse order
  const headers = Object.keys(data); // TODO: look at this
  // console.log(headers);
  const rowsPerPage = 13; // adjust as needed
  const pages = chunk(headers, rowsPerPage);
  const columnCount = data[headers[0]]?.length;

  const createTableBody = (pageHeaders, sortOrder) => {
    // console.log({ pageHeaders, sortOrder})

    let parameters;
    if (sortOrder !== undefined) {
      parameters = sortOrder;
    } else {
      parameters = pageHeaders;
    }

    // console.log(pageHeaders);
    // console.log(sortOrder);
    // console.log(data);

    return parameters.map((key) => {
      if (!data[key]) return;
      if (typeof data[key][1] !== "object") {
        // console.log(data[key]);
        // console.log(data);
        return (
          <StyledTableRow key={key}>
            <StyledTableCell
              component="th"
              scope="row"
              firstCol
              firstColor={settings.secondaryColor}
              secondColor={settings.tertiaryColor}
            >
              {data[key][0].screen_name || prettierName(key)}
            </StyledTableCell>
            <StyledTableCell
              // key={valueIndex}
              secondCol
              firstColor={settings.secondaryColor}
              secondColor={settings.tertiaryColor}
            >
              {/* {data[key][0].units} */}
              {/*console.log(toNormalDecimal(data[key][1], key, data[key][0].units).split("_")[1])*/}
              {
                toNormalDecimal(data[key][1], key, data[key][0].units).split(
                  "_"
                )[1]
              }
            </StyledTableCell>
            {/* {data[key].map((value, valueIndex) => { */}
            {cables.map((cable, valueIndex) => {
              // if (valueIndex === 0) {
              //   return (
              //     <StyledTableCell
              //       key={valueIndex}
              //       secondCol
              //       firstColor={settings.secondaryColor}
              //       secondColor={settings.tertiaryColor}
              //     >
              //       {value.units.replace(/_/g, ' ')}
              //     </StyledTableCell>
              //   );
              // } else {
              // if (value && valueIndex !== 0) {
              // value = value.toString();
              // value = value.toString();
              if (cable[key] !== undefined) {
                return (
                  <StyledTableCell key={valueIndex}>
                    {
                      toNormalDecimal(
                        cable[key].val,
                        key,
                        cable[key].val.units
                      ).split("_")[0]
                    }
                  </StyledTableCell>
                );
              } else {
                return (
                  <StyledTableCell key={valueIndex}>
                    {/*console.log(toNormalDecimal(value, key, data[key][0].units).split("_")[0])*/}
                    {/* {
                        toNormalDecimal(value, key, data[key][0].units).split(
                          "_"
                        )[0]
                      } */}
                    N/A
                  </StyledTableCell>
                );
              }
              // }
              // return (
              //   <StyledTableCell key={valueIndex}></StyledTableCell>
              // );

              // }
            })}
          </StyledTableRow>
        );
      } else {
        console.log(data[key]);
      }
    });
  };

  return pages.map((pageHeaders, pageIndex) => (
    <Page key={pageIndex}>
      <Front>
        <HeaderBox bgColor={"white"}>
          <LogoBox>
            <Logo src={logo.data ? logo.data : ""} alt="Company Logo" />
          </LogoBox>
          <Header>
            <HeaderTitle>{settings["Project Name"]}</HeaderTitle>
            {/* <HeaderName>Powered by VEKTA GROUP</HeaderName> */}
          </Header>
          <TopRightDetails>
            <Detail>
              <DetailName>Revision No:</DetailName>
              <DetailValue>{settings["Revision Number"]}</DetailValue>
            </Detail>
            <Detail>
              <DetailName>Document No:</DetailName>
              <DetailValue>{settings["Document Number"]}</DetailValue>
            </Detail>
            <Detail>
              <DetailName>Issue Date:</DetailName>
              <DetailValue>{settings["Date"]}</DetailValue>
            </Detail>
            <Detail>
              <DetailName>Powered by VEKTA GROUP</DetailName>
            </Detail>
          </TopRightDetails>
        </HeaderBox>
        <TitleGap bgColor={settings.primaryColor} />
        <TableTitle>
          {pages.length > 1
            ? `${title} - Part ${pageIndex + 1} of ${pages.length}`
            : title}
        </TableTitle>

        <TableBox>
          <TableContainer component={Paper}>
            <Table aria-label={`customized-table-page-${pageIndex}`}>
              <TableHead>
                <StyledTableRow header>
                  <StyledTableCell header headerColor={settings.primaryColor}>
                    Parameters
                  </StyledTableCell>{" "}
                  {/* First column header changed to "Units" */}
                  <StyledTableCell
                    key={"unit cell"}
                    header
                    secondCol
                    headerColor={settings.primaryColor}
                  >
                    Units
                  </StyledTableCell>
                  {Array.from({ length: cables.length }, (_, index) => (
                    <StyledTableCell
                      key={index}
                      header
                      headerColor={settings.primaryColor}
                    >{`Cable ${index + 1}`}</StyledTableCell>
                  ))}
                </StyledTableRow>
              </TableHead>
              <TableBody>{createTableBody(pageHeaders, sortOrder)}</TableBody>
            </Table>
          </TableContainer>
        </TableBox>
      </Front>
    </Page>
  ));
};

export default TableRender;
