import React, { useState } from "react";
import styled from "styled-components";
import { Switch, FormControlLabel, Modal } from "@mui/material";
import { useEffect } from "react";
import { CSVLink } from "react-csv";
import PdfForm from "./PdfForm";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { MeButton, PrimeButton, SecButton } from "../../styles/styles";
import DatasheetConfig from "./DatasheetConfig";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileArrowDown } from "@fortawesome/free-solid-svg-icons";
import ContextMenu from "../../common/ContextMenu";

import { BeatLoader } from "react-spinners";

import * as XLSX from 'xlsx';

function BottomBar({
  cables,
  setPreviewPDF,
  previewPDF,
  logo,
  setLogo,
  settings,
  setSettings,
  includedSpecs,
  setIncludedSpecs,
  workspace,
  setWorkspace
}) {
  const [pdfReady, setPdfReady] = useState(false); // State to check if the PDF is ready for preview
  const [data, setData] = useState([[]]); // Replace null with your data when available
  const [csvData, setCsvData] = useState([[]]);
  const [openStyles, setOpenStyles] = useState(false)
  const [openSettings, setOpenSettings] = useState(false)
  const [preSetConfig, setPreSetConfig] = useState(null);

  const [csvDownloadMenu, setCsvDownloadMenu] = useState({ open: false, element: null });
  const [loading, setLoading] = useState({ csv: false, pdf: false })

  const downloadPDF = () => {
    const reportContainer = document.getElementById("reportContainer");
    const pages = reportContainer.getElementsByClassName("pageClassName"); // Ensure your <Page> components have this class
    const canvasWidth = 794;
    const canvasHeight = 1122;
    const pdf = new jsPDF({
      orientation: canvasWidth > canvasHeight ? "landscape" : "portrait",
      unit: "pt",
      format: [canvasWidth, canvasHeight],
      compress: true,
    });

    const renderPage = (index) => {
      if (index < pages.length) {
        html2canvas(pages[index], { scale: 2 }).then((canvas) => {
          // Add page if it's not the first one
          if (index > 0) {
            pdf.addPage();
          }
          pdf.addImage(
            canvas.toDataURL("image/png"),
            "PNG",
            0,
            0,
            canvasWidth,
            canvasHeight,
            undefined,
            "FAST"
          );
          renderPage(index + 1); // Render next page
        });
      } else {
        // Save the PDF after all pages have been rendered
        pdf.save("report.pdf");
        setLoading(loading => ({ ...loading, pdf: false }))
      }
    };

    renderPage(0); // Start rendering from the first page
  };

  const handleClose = () => {
    setOpenSettings(false);
    setOpenStyles(false);
  }

  const downloadExcel = () => {
    // Read the JSON file
    // fetch('input.json')
    //     .then((response) => response.json())
    //     .then((data) => {
    //         console.log(data)
    // Post the JSON data to the API
    const temp = [...cables];

    // Cables now include cable_name so no need to add it
    // for (let i = 0; i < temp.length; i++) {
    //   const cable = temp[i];
    //   cable["cable_name"] = {
    //     "dependencies": [],
    //     "description": "abc",
    //     "units": "string",
    //     "val": "Cable " + (i + 1)
    //   }
    // }

    fetch('https://europe-west1-vekta-functions-380610.cloudfunctions.net/vecta_cables_dataexport_v_2_0_0', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(temp),
    })
      .then((response) => response.json())
      .then((data) => {
        generateExcel(data);
      })
      .then((responseJson) => {
        // Optionally, handle the response from the API
        // For example, you could display a message to the user
      })
      .catch((error) => {
        console.error('Error posting JSON data:', error);
        setLoading(loading => ({ ...loading, csv: false }))
      })
    // })
    // .catch((error) => {
    //     console.error('Error reading JSON file:', error);
    // });
  };


  const downloadFlexExcel = () => {
    // Read the JSON file
    // fetch('input.json')
    //     .then((response) => response.json())
    //     .then((data) => {
    //         console.log(data)
    // Post the JSON data to the API
    const temp = [...cables];

    // Cables now include cable_name so no need to add it
    // for (let i = 0; i < temp.length; i++) {
    //   const cable = temp[i];
    //   cable["cable_name"] = {
    //     "dependencies": [],
    //     "description": "abc",
    //     "units": "string",
    //     "val": "Cable " + (i + 1)
    //   }
    // }

    fetch('https://europe-west1-vekta-functions-380610.cloudfunctions.net/vecta_cables_dataexport_v_2_0_0', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(temp),
    })
      .then((response) => response.json())
      .then((data) => {
        generateExcelForFlex(data);
      })
      .then((responseJson) => {
        // Optionally, handle the response from the API
        // For example, you could display a message to the user
      })
      .catch((error) => {
        console.error('Error posting JSON data:', error);
        setLoading(loading => ({ ...loading, csv: false }))
      })
    // })
    // .catch((error) => {
    //     console.error('Error reading JSON file:', error);
    // });
  };



  // Generate Excel file for power factory
  const generateExcel = (data) => {

    const wb = XLSX.utils.book_new();

    Object.entries(data.TypLne).forEach(([sheetName, rows]) => {
      const ws = XLSX.utils.aoa_to_sheet([]);
      XLSX.utils.book_append_sheet(wb, ws, sheetName);

      const columnNames = getColumnNames(rows);
      /// get column names without the first three characters
      const columnNamesHeader = columnNames.map((name) => name.substring(3));

      const dataRows = Object.values(rows).map((row) => {
        return columnNames.map((columnName) => {
          return row[columnName];
        });
      });
      XLSX.utils.sheet_add_json(ws, [columnNamesHeader], { origin: 'A1', skipHeader: true });
      XLSX.utils.sheet_add_json(ws, dataRows, { origin: 'A2', skipHeader: true });
    });

    XLSX.writeFile(wb, 'power_factory.xlsx');
    setLoading(loading => ({ ...loading, csv: false }))
  };


  // Generate Excel file for orcaflex
const generateExcelForFlex = (data) => {
  console.log("Starting generateExcelForFlex function");

  if (!data || !data.Geometry_summary || !data.Mechanical_summary) {
    console.error("Invalid data structure");
    return;
  }

  console.log("Data structure is valid");

  const wb = XLSX.utils.book_new();

  // Function to sanitize sheet names
  const sanitizeSheetName = (name) => {
    return name.replace(/[:\\/?*\[\]]/g, '');
  };

  // Iterate over each cable type in Geometry_summary
  Object.keys(data.Geometry_summary).forEach((cableType) => {
    const sanitizedCableType = sanitizeSheetName(cableType);
    console.log(`Processing cable type: ${sanitizedCableType}`);
    const ws = XLSX.utils.aoa_to_sheet([]);
    XLSX.utils.book_append_sheet(wb, ws, sanitizedCableType);

    // Get geometry summary data
    const geometryRows = Object.values(data.Geometry_summary[cableType]);
    const geometryColumnNames = getColumnNamesForFlex(geometryRows);
    const geometryColumnNamesHeader = geometryColumnNames.map((name) => name.substring(3));

    console.log(`Geometry column names for ${sanitizedCableType}:`, geometryColumnNames);
    const geometryDataRows = geometryRows.map((row) => {
      return geometryColumnNames.map((columnName) => {
        return row[columnName];
      });
    });
    console.log(`Geometry data rows for ${sanitizedCableType}:`, geometryDataRows);

    // Get mechanical summary data
    const mechanicalRows = Object.values(data.Mechanical_summary[cableType]);
    const mechanicalColumnNames = getColumnNamesForFlex(mechanicalRows);
    //// remove first three characters from the columns names
    const mechanicalColumnNamesHeader = mechanicalColumnNames.map((name) => name.substring(3));


    console.log(`Mechanical column names for ${sanitizedCableType}:`, mechanicalColumnNames);
    const mechanicalDataRows = mechanicalRows.map((row) => {
      return mechanicalColumnNames.map((columnName) => {
        return row[columnName];
      });
    });
    console.log(`Mechanical data rows for ${sanitizedCableType}:`, mechanicalDataRows);

    // Add geometry summary data to the sheet
    XLSX.utils.sheet_add_json(ws, [geometryColumnNamesHeader], { origin: 'A1', skipHeader: true });
    XLSX.utils.sheet_add_json(ws, geometryDataRows, { origin: 'A2', skipHeader: true });

    // Calculate the starting column for mechanical data (geometry columns + 1 space)
    const mechanicalStartColumn = String.fromCharCode('A'.charCodeAt(0) + geometryColumnNames.length + 1);

    // Add mechanical summary data to the sheet
    XLSX.utils.sheet_add_json(ws, [mechanicalColumnNamesHeader], { origin: `${mechanicalStartColumn}1`, skipHeader: true });
    XLSX.utils.sheet_add_json(ws, mechanicalDataRows, { origin: `${mechanicalStartColumn}2`, skipHeader: true });
  });

  console.log("Writing Excel file");
  XLSX.writeFile(wb, 'orcaflex_uflex.xlsx');
  console.log("Excel file written successfully");
  setLoading(loading => ({ ...loading, csv: false }));
  console.log("Loading state updated");
};




const getColumnNamesForFlex = (rows) => {
    const firstRow = Object.values(rows)[0];
    return Object.keys(firstRow);
  };

  const getColumnNames = (rows) => {
    const columnNames = new Set();
    Object.values(rows).forEach((row) => {
      Object.keys(row).forEach((columnName) => {
        columnNames.add(columnName);
      });
    });
    return [...columnNames];
  };


  useEffect(() => {
    if (cables.length > 0) {
      setData(cables);

      // Filter out the object from the datasheet so we are only working with the right specs
      const temp = [...cables];

      for (let i = 0; i < temp.length; i++) {
        Object.keys(temp[i]).map((key) => {
          if (typeof temp[i][key].val === "object") {
            delete temp[i][key]
          }
        })
      }
      // setIncludedSpecs(Object.keys(temp[0]))
      setPdfReady(true);
    } else {
      setData([[]]);
      setPdfReady(false);
    }
  }, [cables]);
// TODO: CSV DOWNLOAD NOT WORKING - Check this
  useEffect(() => {
    setCsvData(() => {
      const table = [];
      const titles = [];
      data.map((item, i) => {
        const cable = [`Cable ${i+1}`];
        Object.keys(item).map(
          (attr) => {
            if (includedSpecs.indexOf(attr) !== -1) {
              if (titles.indexOf(attr) === -1) titles.push(attr)
              cable.push(` ${item[attr].val} ${item[attr].units}`)
            }
          });

        table.push(cable)
      });

      const titled_table = [["Cable Name", ...titles], ...table];

      function transpose(matrix) {
        return matrix[0].map((_, colIndex) =>
          matrix.map((row) => row[colIndex])
        );
      }
      const transposed = transpose(titled_table);
      return transposed;
    });
  }, [data, includedSpecs]);

  return (
    <BottomBarBox>
      {/* Group of buttons for various actions */}
      <ButtonGroup>
        {/* Toggle switch for PDF preview */}
        <div id="preview_pdf">
          <FormControlLabel
            control={
              <Switch
                checked={previewPDF}
                onChange={() => {
                  setPreviewPDF((previewPDF) => !previewPDF);
                }}
                name="previewPDF"
                size="small"
                disabled={!pdfReady}
                style={{ color: "#009ec6" }}
              />
            }
            label="PREVIEW"
            labelPlacement="start"
            style={{ fontWeight: "800" }}
          />
        </div>
        {/* Button to edit report theme */}
        <div id="document_settings">
          <SecButton title="Edit Report Theme" disabled={!pdfReady || !cables || cables.length === 0 } onClick={() => setOpenStyles(true)}>
            Edit Theme
          </SecButton>
        </div>
      </ButtonGroup>
  
      <ButtonGroup>
        {/* Button to download CSV */}
        <div id="download_csv">
          <PrimeButton
            title="Download CSV and Excel files"
            disabled={!pdfReady}
            onClick={(e) => setCsvDownloadMenu({ open: true, element: e.target })}
          >
            {/* Show download icon or loading spinner */}
            {!loading.csv ? <><FontAwesomeIcon icon={faFileArrowDown} /> Data</> : <BeatLoader color="white" />}
          </PrimeButton>
          {/* Context menu for CSV download options */}
          <ContextMenu
            status={csvDownloadMenu}
            item={"csv"}
            reset={() => setCsvDownloadMenu({ open: false, element: null })}
            sectionOne={[
              {
                name: <div>Power Factory</div>, 
                callback: () => {
                  setLoading(loading => ({ ...loading, csv: true }))
                  downloadExcel();
                  setCsvDownloadMenu({ open: false, element: null })
                }
              }
            ]}
            sectionTwo={[
              {
                name: <div>Datasheet</div>,
                callback: () => {
                  const csv_link = document.getElementById("csv_link_datasheet");
                  csv_link.click();
                  setCsvDownloadMenu({ open: false, element: null });
                },
              },
            ]}
            sectionThree={[
              {
                name: <div>OrcaFlex/UFLEX</div>, 
                callback: () => {
                  setLoading(loading => ({ ...loading, csv: true }))
                  downloadFlexExcel();
                  setCsvDownloadMenu({ open: false, element: null })
                }
              }
            ]}
            position={{ vertical1: "right", vertical2: "bottom", horizontal1: "right", horizontal2: "right" }}
          />
        </div>
        {/* Button to download PDF */}
        <div id="download_pdf">
          <PrimeButton
            title="Download Datasheet PDF"
            disabled={!pdfReady}
            onClick={() => {
              setLoading(loading => ({ ...loading, pdf: true }))
              downloadPDF();
            }}
          >
            {/* Show download icon or loading spinner */}
            {!loading.pdf ? <><FontAwesomeIcon icon={faFileArrowDown} /> Report</> : <BeatLoader color="white" />}
          </PrimeButton>
        </div>
      </ButtonGroup>
  
      {/* Invisible link for CSV download */}
      <Invisible>
        <CSVLink
          id="csv_link_datasheet"
          data={csvData}
          filename={"cable_datasheet.csv"}
          href={`blob:${window.location}/5f6bdc13-b01d-4fd3-ab27-9cf3d3d019a9`}
        ></CSVLink>
      </Invisible>
  
      {/* Modal for editing PDF settings */}
      <Modal
        open={openStyles}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <PdfForm
          settings={settings}
          setSettings={setSettings}
          handleClose={handleClose}
          logo={logo}
          setLogo={setLogo}
        />
      </Modal>
    </BottomBarBox>
  );
}

export default BottomBar;

const BottomBarBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* border: 3px solid red; */
  //position: absolute;
  //width: 100%;
  //bottom: 0px;
`;


const ButtonGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
`;

const Invisible = styled.div`
  display: None;
`;

const RightGroup = styled.div`
  position:absolute;
  display: flex;
  gap: 0px;
  right:10px
`;
