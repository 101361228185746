import "primereact/resources/themes/lara-light-cyan/theme.css";
import React, { useState, useEffect } from "react";
import styled from "styled-components";

import Analysis from './components/analysis/Analysis';
import Collection from './components/collection/Collection';
import Results from './components/results/Results';
import SideBar from './suvc/sidebar/SideBar';

import { onAuthStateChanged } from 'firebase/auth';
import { auth, db } from './firebase';
import { doc, getDoc } from 'firebase/firestore';
import { downloadWorkspace, updateWorkspace } from './functions/functions';
import HomeFour from './suvc/landing';

const topBarHeight = "50px";

const StandardContainerRow = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
`;

const StandardContainerColumn = styled.div`
  position: relative;
  height: 100%;
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;

  width: 35%;
  max-width: 35%;
`;
const CenterStage = styled.div`
  /* position: absolute; */
  display: flex;
  justify-content: center;
  align-items: center;
  width: calc(100% - 100px);
  height: 95vh;
  margin: 24px;
  max-width: calc(100% - 100px);
  /* max-height: 800px; */
  /* border:3px solid red; */
  /* left: 50%;
  top: 50%;
  transform: translate(-50%, -50%); */
`;
const FullStage = styled.div`
  height: 100%;
  width: 100%;

  display: flex;
  background-color: hsla(0,0%,83%,.329);
  /* gap: 16px; */
  /* background-image: url("./background.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat; */
`;

function App() {
  const [page, setPage] = useState("cables");
  const [user, setUser] = useState(null);
  const [authorized, setAuthorized] = useState(false);

  const [toAnalyse, setToAnalyse] = useState([])
  const [runSettings, setRunSettings] = useState([])

  const [runAPI, setRunAPI] = useState(false);

  const [activeWorkspace, setActiveWorkspace] = useState(null)
  const [homePage, setHomePage] = useState(false)

  const loadWorkspace = async (user) => {
    const default_workspace = {
      output_settings: ["Geometric Price Prediction"],
      collection: {
        selectedFilters: [
          "Size",
          "Nominal voltage",
          "Cable type",
          "Core material",
          "organisation",
          "Last edited by"
        ],
      },
      analysis: {
        defaultSettings: {
          "date": "2018-01",
          "currency": "USD",
          "profit_margin": 2,
          "region": "Europe",
          "single_core_cable_arrangement": "trefoil",
          "single_core_cable_bounding_type": "cross_bounded",
          "core_temp": 90,
          "metallic_screen_temp": 80,
          "bury_depth": 1.0,
          "ground_thermal_resistivity": 0.9,
          "dry_ground_thermal_resistivity": 1.1,
          "system_voltage": "Cable Voltage", // changes based on cable
          "ground_temp": 12,
          "critical_ground_temperature": 25,
          "air_temperature": 20,
          "system_frequency": 50,
          "cable_environment_type": "direct_in_ground",
          "ground_environment_thermal_condition": "no_soil_drying",
          "air_environment_thermal_condition": "no_solar_radiation",
          "optical_fibre_cores_number": 60,
        }
      }
    }

    const user_workspace = await downloadWorkspace(user.uid)

    console.log(user_workspace);

    if (user_workspace) {
      setActiveWorkspace({ ...default_workspace, ...user_workspace })
    } else {
      setActiveWorkspace(default_workspace)
    }

  }

  const checkIfAuthorized = async (user) => {
    const permissionRef = doc(db, "permissions", user.uid);
    const permissionsDoc = await getDoc(permissionRef)
    if (permissionsDoc.data()) {
      const permissions = permissionsDoc.data()
      console.log({ permissions })

      if (permissions && permissions.permissions[0] === "vekta") {
        setAuthorized(true);
        loadWorkspace(user)
      } else {
        setAuthorized(false);
        setUser(null);
        alert("You are not authorized to use this application")
        // auth.signOut()
      }
    }
  }

  const handleToolLaunch = () => {

  }

  useEffect(() => {
    if (user) {
      checkIfAuthorized(user)
    }
  }, [user])


  useEffect(() => {
    if (user && authorized) {
      updateWorkspace(user, activeWorkspace)
      setHomePage(false)
      console.log("active workspace changed")
      console.log(activeWorkspace)
    }
  }, [activeWorkspace, user, authorized])

  // Handle authentication state change
  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        // const getToken = async () => {
        setUser(user);

      } else {
        setUser(null);
        setAuthorized(false);
      }
    });

    // TODO - Load in workspace from the firestore
  }, []);



  return (
    <div style={{ height: "100%" }}>
      {authorized ? (
        <>
          {authorized && user && !homePage ? (
            <FullStage>
              <SideBar page={page} user={user} setPage={setPage} setHomePage={setHomePage} />
              {
                activeWorkspace ? (
                  <CenterStage>
                    <StandardContainerRow>
                      <StandardContainerRow >
                        <StandardContainerColumn>
                          <Collection
                            user={user}
                            toAnalyse={toAnalyse}
                            setToAnalyse={setToAnalyse}
                            activeWorkspace={activeWorkspace}
                            setActiveWorkspace={setActiveWorkspace} />
                          <Analysis
                            user={user}
                            toAnalyse={toAnalyse}
                            setToAnalyse={setToAnalyse}
                            runSettings={runSettings}
                            setRunSettings={setRunSettings}
                            setRunAPI={setRunAPI}
                            activeWorkspace={activeWorkspace}
                            setActiveWorkspace={setActiveWorkspace} />
                        </StandardContainerColumn>
                        <Results runSettings={runSettings} toAnalyse={toAnalyse} runAPI={runAPI} setRunAPI={setRunAPI} workspace={activeWorkspace.output_settings} setWorkspace={setActiveWorkspace} />
                      </StandardContainerRow>
                    </StandardContainerRow>
                  </CenterStage>
                ) : null
              }
              <span style={{ fontSize: "12px", position: "absolute", bottom: "4px", right: "16px", color: "black" }}>Powered By &#169; <a href='https://vektagroup.com/' target='_blank'>Vekta Group</a> V3.4.1 | Contributions &#169; <a href='https://webstore.iec.ch/publication/68120' target='_blank'>IEC</a> & &#169; <a href='https://cigre-usnc.org/technical-brochure-ref-908/' target='_blank'>CIGRE</a></span>
            </FullStage>
          ) : <HomeFour homePage={homePage} setHomePage={setHomePage} />}
        </>
      ) : (
        // <LandingPage handleToolLaunch={handleToolLaunch} />
        <HomeFour homePage={false} />
      )}



    </div>
  );
}

export default App;