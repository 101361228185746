import React, { useEffect, useState } from "react";
import { Page } from "./ReportStyles";
import styled from "styled-components";
import section_url from "./Section.png";

const Front = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
`;
const HeaderBox = styled.div`
  position: relative;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 200px;
  width: 100%;
  background-color: ${(props) => props.bgColor};
`;
const LogoBox = styled.div`
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  width: 200px;
  height: 200px;
`;
const Logo = styled.img`
  width: 100%;
  box-sizing: border-box;
  object-fit: scale-down;
`;

const Header = styled.div`
  box-sizing: border-box;
  /* width: 100%; */
  padding: 40px;
  padding-left: 100px;
  display: flex;
  flex-direction: column;
`;

const HeaderTitle = styled.div`
  font-size: 32px;
  padding-bottom: 20px;
  box-sizing: border-box;
  text-align: right;
  color: white;
  font-weight: 400;
`;
const HeaderName = styled.div`
  text-align: right;
  font-size: 20px;
  color: white;
`;

const TitleBar = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
`;
const TitleGap = styled.div`
  height: 10px;
  background-color: ${(props) => props.bgColor};
`;
const TitleName = styled.div`
  background-color: ${(props) => props.bgColor};
  width: 400px;
  height: 40px;
  font-size: 24px;
  font-weight: 800;
  display: flex; // Set the display to flex
  align-items: center; // Vertically center the content
  text-align: center;
  justify-content: center;
  color: white;
`;
const TitleRight = styled.div`
  width: 100%;
  opacity: 40%;
  background-color: ${(props) => props.bgColor};
`;

const Body = styled.div`
  height: calc(100% - 200px - 60px - 200px);
  box-sizing: border-box;

  display: flex;
  flex-direction: row;
  /* overflow: hidden; */
  position: relative;
`;

const Section = styled.img`
  position: absolute;
  top: -50px;
  right: -200px;
  width: 700px;
`;

const DetailsColumn = styled.div`
  width: 200px;
  height: 100%;
  box-sizing: border-box;
  background-color: ${(props) => props.bgColor};
  display: flex;
  flex-direction: column;
  gap: 12px;
  text-align: right;
  padding: 20px;
`;
const Detail = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
`;
const DetailName = styled.div`
  display: flex;
  flex-direction: column; // Stack the children vertically
  align-items: start; // Align children to the right
  width: 100%; // Ensure it takes full width of parent
  box-sizing: border-box;
  text-align: left;
  font-weight: 600;
  min-height: 40px;
`;

const DetailValue = styled.div`
  display: flex;
  flex-direction: column; // Stack the children vertically
  align-items: flex-start; // Align children to the right: ;
  width: 100%; // Ensure it takes full width of parent
  box-sizing: border-box;
  text-align: left;
  min-height: 40px;
  word-wrap: break-word; // Ensure long words don't overflow
  display: block; // Use block to fill the width
  /* border: 1px solid lightgray; */
  padding: 8px;
  justify-content: center;
  background-color: white;
`;

const FooterBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  height: 200px;
  box-sizing: border-box;
`;
const FooterLeftBox = styled.div`
  background-color: ${(props) => props.bgColor};
  box-sizing: border-box;
  display: flex;
  width: 100%;
`;
const FooterMiddleBox = styled.div`
  box-sizing: border-box;
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 24px;
  justify-content: center;
  position: relative;
  overflow: hidden;
  padding: 0; // Make sure no padding is interfering
`;

const FooterRightBox = styled.div`
  box-sizing: border-box;
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 24px;
  justify-content: center;
  padding: 0; // Make sure no padding is interfering
`;
const DocDetailName = styled.div`
  display: flex;
  flex-direction: column; // Stack the children vertically
  align-items: flex-end; // Align children to the right: ;
  width: 100%; // Ensure it takes full width of parent
  box-sizing: border-box;
  text-align: right;
  height: 40px;
  padding: 0;
  justify-content: center;
  background-color: ${(props) => props.bgColor};
  padding-right: 10px;
  font-weight: 500;
  color: white;
`;
const DocDetailValue = styled.div`
  height: 40px;
  display: flex;
  flex-direction: column; // Stack the children vertically
  align-items: flex-start; // Align children to the right: ;
  width: 100%; // Ensure it takes full width of parent
  box-sizing: border-box;
  text-align: left;
  justify-content: center;
  padding-left: 20px;
  font-weight: 800;
  background-color: ${(props) => props.bgColor};
`;

const MiddleShape = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  transform: translate(-100%, 0) rotate(27.5deg) scaleY(2);
  transform-origin: bottom right;
  background-color: ${(props) => props.bgColor};
`;

const MiddleShape2 = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  transform: translate(-100%, 0) translate(20px, 0) rotate(27.5deg) scaleY(2);
  transform-origin: bottom right;
  background-color: white;
`;

function FrontPage({ logo, settings }) {
  const [color1, setColor1] = useState(settings.primaryColor);
  const [color2, setColor2] = useState(settings.secondaryColor);
  const [color3, setColor3] = useState(settings.tertiaryColor);

  useEffect(() => {
    setColor1(settings.primaryColor);
    setColor2(settings.secondaryColor);
    setColor3(settings.tertiaryColor);
  }, [settings.primaryColor, settings.secondaryColor, settings.tertiaryColor]);

  useEffect(() => {
    console.log(settings);
  }, [settings]);

  return (
    <Page>
      <Front>
        <HeaderBox bgColor={color1}>
          <LogoBox>
            <Logo src={logo.data ? logo.data : ""} alt="Company Logo" />
          </LogoBox>
          <Header>
            <HeaderTitle>POWER CABLE DATASHEETS</HeaderTitle>
            <HeaderName>{settings["Project Name"]}</HeaderName>
          </Header>
        </HeaderBox>
        <TitleGap bgColor={color3} />
        <TitleBar bgColor={color2}>
          <TitleName bgColor={color2}>PROJECT DETAILS</TitleName>
          <TitleRight bgColor={color2} />
        </TitleBar>
        <Body>
          <Section src={section_url} />
          <DetailsColumn bgColor={color3}>
            <Detail>
              <DetailName>Project</DetailName>
              <DetailValue>{settings["Project Name"]}</DetailValue>
            </Detail>
            <Detail>
              <DetailName>Client</DetailName>
              <DetailValue>{settings["Client Name"]}</DetailValue>
            </Detail>
            <Detail>
              <DetailName>Notes</DetailName>
              <DetailValue>{settings["Notes"]}</DetailValue>
            </Detail>
          </DetailsColumn>
        </Body>
        <FooterBox>
          <FooterLeftBox bgColor={color1} />
          <FooterMiddleBox>
            <DocDetailName bgColor={color1}>Date Of Issue</DocDetailName>
            <DocDetailName bgColor={color1}>Revision Number</DocDetailName>
            <DocDetailName bgColor={color1}>Document Number</DocDetailName>
            <MiddleShape2 />
            <MiddleShape bgColor={color2} />
          </FooterMiddleBox>
          <FooterRightBox>
            <DocDetailValue bgColor={color2}>{settings["Date"]}</DocDetailValue>
            <DocDetailValue bgColor={color2}>
              {settings["Revision Number"]}
            </DocDetailValue>
            <DocDetailValue bgColor={color2}>
              {settings["Document Number"]}
            </DocDetailValue>
          </FooterRightBox>
        </FooterBox>
      </Front>
    </Page>
  );
}

export default FrontPage;
